import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "faq-list"
};
const _hoisted_2 = ["id", "summary", "open"];
const _hoisted_3 = {
    type: "span",
    size: "small-2",
    color: "subdued",
    class: "helpfulness-score"
};
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_rate = _resolveComponent("rate");
    return (_ctx.faqList)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqList, (faqItem) => {
                return (_openBlock(), _createElementBlock("spk-details", {
                    id: 'faq-item-' + faqItem.id,
                    key: faqItem.id,
                    summary: faqItem.question,
                    open: faqItem.isActive ? '' : null
                }, [
                    _createElementVNode("spk-text", _hoisted_3, _toDisplayString(faqItem.score) + " " + _toDisplayString(_ctx.translations.CUSTOM_MSG_PEOPLE_FOUND_USEFUL_LBL), 1 /* TEXT */),
                    _createElementVNode("spk-text", {
                        type: "p",
                        color: "inherit",
                        class: "faq-item-answer",
                        innerHTML: faqItem.answer
                    }, null, 8 /* PROPS */, _hoisted_4),
                    _createVNode(_component_rate, {
                        "ajax-path": _ctx.ajaxPath,
                        "site-code": _ctx.siteCode,
                        language: _ctx.language,
                        translations: _ctx.translations,
                        id: faqItem.id,
                        "faq-item": faqItem,
                        color: "secondary"
                    }, null, 8 /* PROPS */, ["ajax-path", "site-code", "language", "translations", "id", "faq-item"])
                ], 8 /* PROPS */, _hoisted_2));
            }), 128 /* KEYED_FRAGMENT */))
        ]))
        : _createCommentVNode("v-if", true);
}
