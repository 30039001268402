import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["color"];
const _hoisted_2 = { style: { "padding": "16px" } };
const _hoisted_3 = {
    type: "p",
    size: "body-1",
    color: "container-body-on-primary",
    class: "text-center rate-answer-text"
};
const _hoisted_4 = {
    key: 0,
    class: "feedback-buttons"
};
const _hoisted_5 = {
    key: 1,
    type: "p",
    size: "body-1",
    color: "container-body-on-primary",
    class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("spk-container", {
        color: _ctx.color,
        radius: "none"
    }, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("spk-text", _hoisted_3, _toDisplayString(_ctx.translations.CUSTOM_MSG_IS_THIS_ANSWER_HELPFUL), 1 /* TEXT */),
            (!_ctx.rateHasBeenSent)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("spk-button", {
                        color: "tertiary",
                        size: "s",
                        fullwidth: "",
                        onClick: _cache[0] || (_cache[0] = ($event) => { _ctx.trackInteraction($event); _ctx.sendFeedback(_ctx.id, '2'); }),
                        "data-rating": "yes"
                    }, _toDisplayString(_ctx.translations.CUSTOM_MSG_YES), 1 /* TEXT */),
                    _createElementVNode("spk-button", {
                        color: "tertiary",
                        size: "s",
                        fullwidth: "",
                        onClick: _cache[1] || (_cache[1] = ($event) => { _ctx.trackInteraction($event); _ctx.sendFeedback(_ctx.id, '1'); }),
                        "data-rating": "no"
                    }, _toDisplayString(_ctx.translations.CUSTOM_MSG_NO), 1 /* TEXT */)
                ]))
                : (_openBlock(), _createElementBlock("spk-text", _hoisted_5, _toDisplayString(_ctx.translations.CUSTOM_MSG_FEEDBACK_THANK_YOU_MESSAGE), 1 /* TEXT */))
        ])
    ], 8 /* PROPS */, _hoisted_1));
}
